import React, {useCallback, useState} from 'react';
import {Stack, Subheading, ChoiceList} from '@shopify/polaris';

const CartOfferTypeSelector = ({offerType, changeOfferType}) => {
  const [selected, setSelected] = useState(offerType);

  const handleChange = useCallback((value) => {
    setSelected(value[0]);
    changeOfferType(value[0]);
  }, []);

  return (
    <Stack>
      <Subheading>Product selection</Subheading>
      <ChoiceList
        title="Product selection<"
        titleHidden
        choices={[
          {
            label: 'Manual',
            value: 'manual',
            helpText: 'Manually choose the products to show as an upsell',
          },
          {
            label: 'ShopBrain',
            value: 'ai',
            helpText: 'Let our algorithm show the best product recommendations',
          },
        ]}
        selected={selected}
        onChange={handleChange}
      />
    </Stack>
  );
};

export default CartOfferTypeSelector;